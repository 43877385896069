export class ScrollToTop extends HTMLElement {
  #abortController

  constructor() {
    super()
    this.attachShadow({mode: 'open'}).innerHTML = '<slot>↑</slot>'
  }

  connectedCallback() {
    this.#abortController = new AbortController()
    const scrollCB = this.scrollCallback.bind(this)
    window.addEventListener('scroll', scrollCB, {signal: this.#abortController.signal})
    this.scrollCallback()
    this.addEventListener('click', () => { window.scrollTo({top: 0, behavior: 'smooth'}) })
  }

  disconnectedCallback() {
    this.#abortController.abort
  }

  scrollCallback() {
    this.classList.toggle('active', window.scrollY > 100)
  }
}
